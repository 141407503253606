import React, { ReactElement, useEffect, useState } from 'react'
import { VStack, Heading, Spacer, Divider } from '@chakra-ui/layout'
import { AuditLog, renderMetadata } from '../web2/audit_logs/audit_log'
import DoppelLink from '../report_detail/doppel_link'
import { SimpleHorizontalTable, SimpleVerticalTable } from '../shared/simple_table'
import { EnforcementEmailAdder, EnforcementRequest } from './enforcement_email_adder'
import { TemplateSection } from './enforcement_template'
import {
  DOMAIN_PLATFORM,
  ENFORCEMENT_STATUS,
  EnforcementPlatform,
} from '@/utils/constants'
import { CollectionReportChangeType, EnforcementEmailStage } from '@/generated/enums'
import { useGetSpoofTemplatesQuery } from '@/generated/graphql'
import { PHISHING_DOMAINS_ENFORCEMENT_PLATFORMS } from '@/utils/enforcement_utils'
import { getTimestampDisplay } from '@/utils/time'
import { Td, Tr, Text, Box } from '@chakra-ui/react'
import { DOPPEL_SECURE } from '@/utils/style'
import { unSnakeCase } from '@/utils/string_utils'
import DoppelFormLabel from '../shared/forms/doppel_form_label'

export const GOOGLE_GROUPS_URL =
  'https://groups.google.com/a/doppel.com/g/takedowns/search'

export function EnforcementDetailRow({
  enforcementRequest,
  refetchEnforcementDetails,
  isEmployeeView,
}: {
  enforcementRequest: EnforcementRequest
  refetchEnforcementDetails: () => void
  isEmployeeView: boolean
}) {
  // enforcement request data
  const rowMappings = [
    { name: 'Platform', key: 'platform' },
    { name: 'Type', key: 'type' },
    { name: 'Status', key: 'status' },
    { name: 'Created At', key: 'created_at' },
  ]

  if (isEmployeeView) {
    rowMappings.push(
      { name: 'Updated At', key: 'updated_at' },
      { name: 'Internal ID', key: 'id' },
      { name: 'External ID', key: 'submission_external_id' },
      { name: 'Submission Proof URL', key: 'submission_proof_url' },
    )
  } else {
    rowMappings.push({ name: 'External Case ID', key: 'submission_external_id' })
  }

  const data = {
    ...enforcementRequest?.metadata,
    created_at: getTimestampDisplay(enforcementRequest.metadata.created_at),
    updated_at: getTimestampDisplay(enforcementRequest.metadata.updated_at),
  }

  const enforcementPlatform = enforcementRequest.metadata
    .platform as EnforcementPlatform
  const enforcementType = enforcementRequest.metadata.type
  const { data: templates, loading: _ } = useGetSpoofTemplatesQuery({
    variables: {
      platform:
        (PHISHING_DOMAINS_ENFORCEMENT_PLATFORMS.includes(enforcementPlatform) &&
          DOMAIN_PLATFORM) ||
        enforcementPlatform,
      stage: EnforcementEmailStage.FOLLOW_UP,
      type: enforcementType,
    },
  })
  const firstTemplateData = templates?.enforcement_templates[0]
  const [templateBody, setTemplateBody] = useState('')

  const resetState = () => {
    setTemplateBody(firstTemplateData?.template_body || '')
  }
  useEffect(resetState, [firstTemplateData])

  // enforcement request changes data
  const changelogColumns = ['Date', 'Log Type', 'Value', 'Metadata', 'Changed By']
  const changelogData = getChangeLogs(enforcementRequest?.enforcementRequestChanges)

  const emailLogColumns = ['Date', 'Email Type', 'Email Status', 'Metadata']

  const emailLogData = getEmailLogs(enforcementRequest?.enforcementRequestChanges)

  const title = `${enforcementPlatform} - ${enforcementType}`

  if (
    !isEmployeeView &&
    enforcementRequest.metadata.status === ENFORCEMENT_STATUS.CANCELED
  ) {
    return <></>
  }
  return (
    <VStack align="left" width="100%">
      <Box textAlign="center" width="100%">
        <Heading size="md">{title}</Heading>
      </Box>

      <Spacer />

      <Heading size="sm">Metadata</Heading>

      <Spacer />

      <SimpleVerticalTable data={data} rowMappings={rowMappings} />

      <Spacer />

      {emailLogData.length > 0 && (
        <>
          <Divider orientation="horizontal" />

          <Spacer />

          <Heading size="sm">Email Logs</Heading>

          <SimpleHorizontalTable columnNames={emailLogColumns} data={emailLogData} />

          <Spacer />
        </>
      )}

      {changelogData.length > 0 && (
        <>
          <Divider orientation="horizontal" />

          <Spacer />

          <Heading size="sm">Changes</Heading>
        </>
      )}

      {enforcementRequest?.metadata?.platform &&
        enforcementRequest?.metadata?.submission_external_id &&
        isEmployeeView && (
          <DoppelLink
            href={`${GOOGLE_GROUPS_URL}?q=${enforcementRequest.metadata.platform}%20${enforcementRequest.metadata.submission_external_id}`}
            maxWidth={250}
            name={'Search Emails by External ID'}
          />
        )}

      {changelogData.length > 0 && (
        <>
          <SimpleHorizontalTable columnNames={changelogColumns} data={changelogData} />

          <Spacer />
        </>
      )}

      {isEmployeeView && (
        <EnforcementEmailAdder
          enforcementRequest={enforcementRequest}
          refetchEnforcementDetails={refetchEnforcementDetails}
        />
      )}

      <Spacer />

      {firstTemplateData && isEmployeeView && (
        <TemplateSection
          label={'Follow up Template'}
          moreInfoInputs={null}
          template={templateBody}
        />
      )}

      <Spacer />

      {enforcementRequest?.metadata?.retraction_description &&
        enforcementRequest?.metadata?.retraction_proof_url &&
        (enforcementRequest.metadata.status === ENFORCEMENT_STATUS.RETRACTED ||
          enforcementRequest.metadata.status === ENFORCEMENT_STATUS.RETRACTION_SENT) &&
        isEmployeeView &&
        renderRetractionDetails(enforcementRequest)}
    </VStack>
  )
}

function renderRetractionDetails(
  enforcement_request: EnforcementRequest,
): ReactElement {
  // Retraction data to display
  const rowMappings = [
    { name: 'Retraction Proof URL', key: 'retraction_proof_url' },
    { name: 'Retraction Description', key: 'retraction_description' },
  ]
  return (
    <VStack align="left" width="100%">
      <Divider orientation="horizontal" />

      <DoppelFormLabel formLabel="Retraction Information" />

      <SimpleVerticalTable
        data={enforcement_request.metadata}
        rowMappings={rowMappings}
      />
    </VStack>
  )
}

function getEmailLogs(enforcement_request_logs) {
  return enforcement_request_logs
    ?.filter(
      (change) => change.type === CollectionReportChangeType.ENFORCEMENT_EMAIL_STATUS,
    )
    .map((change) => (
      <Tr key={change?.id}>
        <Td>
          <Text as="span" color={DOPPEL_SECURE} fontSize={12}>
            {getTimestampDisplay(change.timestamp)}
          </Text>
        </Td>

        <Td>
          <Text as="span" fontSize={12} noOfLines={3}>
            {unSnakeCase(change.enforcement_email?.type)}
          </Text>
        </Td>

        <Td>
          <Text as="span" fontSize={12} noOfLines={3}>
            {unSnakeCase(change.new_value)}
          </Text>
        </Td>

        <Td>{renderMetadata(change)}</Td>
      </Tr>
    ))
}

function getChangeLogs(enforcement_request_logs) {
  return enforcement_request_logs
    ?.filter(
      (change) => change.type !== CollectionReportChangeType.ENFORCEMENT_EMAIL_STATUS,
    )
    .map((change) => <AuditLog auditLog={change} key={change?.id} />)
}
