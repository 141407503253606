import { Box } from '@chakra-ui/react'
import ImagePreviewSection from '../report_detail/image_preview_section'
import { Order_By, useGetProtectedAssetsByOrgQuery } from '../../generated/graphql'
import { getSocialMediaDataUrl } from '../../utils/domain_utils'
import { useSelectedSpoofReport } from '../../hooks/spoof_report_table_filters'
import { ProductType } from '@/generated/enums'
import { DOPPEL_EMPTY_WEBSITE } from '@/utils/image'
import { ZERO_UUID } from '@/utils/constants'
import { DOPPEL_CHECKMARK } from '@/utils/image'

const OriginalAssetPreview = () => {
  const [selectedSpoofReport] = useSelectedSpoofReport()

  const platform = selectedSpoofReport?.platform
  const product = platform?.product
  const platform_name = product === ProductType.DOMAIN ? 'domain' : platform?.name

  // React hooks must be called in the same order in every render
  // so must call useGetProtectedAssetsByOrgQuery before conditionals
  const assetsWhere = {
    _and: [
      {
        platform_name: { _eq: platform_name },
      },
      {
        original_entity_id: {
          _eq: selectedSpoofReport.spoofing_entity.id,
          _neq: ZERO_UUID,
        },
      },
      {
        is_primary: { _eq: true },
      },
    ],
  }
  const { data: protectedAssetsData } = useGetProtectedAssetsByOrgQuery({
    variables: {
      protectedAssetsWhere: assetsWhere,
      orderBy: [{ created_at: Order_By.Desc }],
    },
    notifyOnNetworkStatusChange: true,
  })

  let previewTitle = null
  let imageURL = null
  let titleLinkOut = null
  let subtitle = 'Original Asset'
  if (product == ProductType.DOMAIN) {
    const asset = protectedAssetsData?.protected_assets?.[0]
    if (asset?.full_url?.screenshot_url) {
      imageURL = asset?.full_url?.screenshot_url
      previewTitle = asset.full_url.url
      titleLinkOut = asset.full_url.unsanitized_url || asset.full_url.url
      subtitle = 'Original Domain'
    } else {
      imageURL = selectedSpoofReport?.spoofing_entity?.screenshot_url
      previewTitle = selectedSpoofReport?.spoofing_entity?.entity_url
      titleLinkOut = selectedSpoofReport?.spoofing_entity?.entity_url
    }
  } else if (product == ProductType.SOCIAL_MEDIA) {
    if (protectedAssetsData && protectedAssetsData.protected_assets.length > 0) {
      const asset = protectedAssetsData.protected_assets[0]
      imageURL = asset?.social_media?.profile_image_url
      previewTitle = asset.social_media
        ? getSocialMediaDataUrl(asset.platform.name, asset.social_media.slug)
        : null
      titleLinkOut = asset.social_media
        ? getSocialMediaDataUrl(asset.platform.name, asset.social_media.slug)
        : null
    }
  } else if (product == ProductType.MOBILE_APP) {
    const mobileAppData = protectedAssetsData?.protected_assets[0]?.mobile_app
    if (mobileAppData) {
      imageURL = mobileAppData?.thumbnail_url ?? DOPPEL_EMPTY_WEBSITE
      previewTitle = mobileAppData?.link ?? mobileAppData?.title
      titleLinkOut =
        mobileAppData.link ??
        `https://app.doppel.com/brands/${selectedSpoofReport.spoofing_entity.id}`
    }
  } else {
    return null
  }
  if (!previewTitle) {
    previewTitle = selectedSpoofReport?.spoofing_entity?.entity_name
    titleLinkOut = `https://app.doppel.com/brands/${selectedSpoofReport.spoofing_entity.id}`
  }

  return (
    <>
      <Box width="40px"></Box>

      <ImagePreviewSection
        backup_image_url={DOPPEL_EMPTY_WEBSITE}
        icon_url={DOPPEL_CHECKMARK}
        imageUrl={imageURL}
        is_flagged={false}
        subtitle={subtitle}
        title={previewTitle}
        title_ref={titleLinkOut}
      />
    </>
  )
}

export default OriginalAssetPreview
