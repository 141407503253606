import { Text } from '@chakra-ui/react'
import React from 'react'
import { DarkWebPlatforms } from '@/utils/constants'
import { useAtom } from 'jotai'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { getContextAroundHighlightedContent } from '@/utils/string_utils'
import RichText from '../shared/rich_text'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'
import { ReportSource } from '@/generated/enums'

export default function DarkWebGenericReportInfo() {
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const darkWebData = selectedSpoofReport?.spoof_matches?.[0]?.dark_web

  if (
    selectedReportExposureType !== DarkWebPlatforms.GENERIC &&
    selectedSpoofReport.type !== DarkWebPlatforms.GENERIC
  ) {
    return <></>
  }

  const isDarkOwlApiLeaks = darkWebData?.source === ReportSource.DARKOWL_API_LEAKS

  const bodyContent =
    getContextAroundHighlightedContent(darkWebData?.body, 5000) ||
    darkWebData?.body?.substring(0, Math.min(5000, darkWebData?.body?.length || 0))

  const bodyLines = bodyContent?.split('\n') || []

  const addEllipsis = (line) => `...${line}...`

  return (
    <>
      <Text fontSize="lg" fontWeight="bold">
        {darkWebData?.title}
      </Text>

      <Text fontSize="12">
        {isDarkOwlApiLeaks ? (
          bodyLines.map((line, index) => (
            <React.Fragment key={index}>
              <RichText html={addEllipsis(line)} />

              <br />
            </React.Fragment>
          ))
        ) : (
          <RichText
            html={
              '...' +
              (getContextAroundHighlightedContent(darkWebData?.body, 5000) ||
                darkWebData?.body?.substring(
                  0,
                  Math.min(5000, darkWebData?.body?.length || 0),
                )) +
              '...'
            }
          />
        )}
      </Text>
    </>
  )
}
